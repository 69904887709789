/* Chat container */
.chat-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 10px;
  direction: rtl; /* Right-to-left for Persian text */
  height: 80vh;
  display: flex;
  flex-direction: column;
}

/* Chat window (history area) */
.chat-window {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  background-color: #fff;
}

/* Individual chat bubbles */
.chat-bubble {
  max-width: 75%;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  word-wrap: break-word;
}

.user-message {
  background-color: #daf8cb;
  align-self: flex-end;
}

.bot-message {
  background-color: #f1f1f1;
  align-self: flex-start;
}

/* Input area */
.input-container {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

input[type="text"] {
  flex-grow: 1;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}
